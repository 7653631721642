import React from 'react'
import { withSnackbar } from 'notistack'
import * as queryString from 'query-string'
//import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Card } from '@material-ui/core'
import { CustomIcon, IconWrapper } from '../components/icon'

import { EMAIL } from '../utils/core'
import Cart from '../backend/cart'
import Colors from '../utils/colors'
import Page from './page'
import SocialLinks from '../components/social_links'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',

    backgroundImage: 'url("https://i.imgur.com/s9kicBk.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
  },
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'row',
    justifyContent: 'space-evenly',
    margin: 20,
    fontFamily: 'Rubik',
  },

  banner: {
    alignSelf: 'center',
  },
  bannerCard: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(2.5),
    borderRadius: 20,
    boxShadow: '0 0 85px 2px rgba(228, 105, 105, 0.2)',
    maxWidth: '1250px',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '90vw',
    minWidth: '280px',
  },
  bannerText: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bannerHeader: {
    fontWeight: 'bold',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    fontFamily: 'Rubik',
  },
  bannerSubtext: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    fontFamily: 'Karla',
  },
  social_links: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  orderCompletedCard: {
    minWidth: '280px',
    width: '90vw',
    maxWidth: '600px',
    borderRadius: 20,
    boxShadow: '0 0 85px 2px rgba(228, 105, 105, 0.2)',
  },
  text: {
    maxWidth: '600px',
    minWidth: '250px',
  },
  icon: {
    marginRight: theme.spacing(1),
    colon: '#34cc1d',
  },
  infoHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 10,
    fontFamily: 'Rubik',
  },
  infoSubtext: {
    textAlign: 'center',
    fontSize: '1rem',
    marginBottom: 20,
    fontFamily: 'Karla',
  },
  infoQuestion: {
    color: '#FF5757',
    fontVariantCaps: 'all-small-caps',
    fontSize: 'x-large',
    fontFamily: 'Rubik',
  },
  infoAnswer: {
    fontFamily: 'Karla',
  },

  paragraph: {
    marginBottom: theme.spacing(2),
  },
  image: {
    maxWidth: '250px',
    maxHeight: '250px',
  },
  link: {
    color: Colors.get('black'),
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}))

const links = {
  facebook: 'https://www.facebook.com/madetouplift/',
  instagram: 'https://www.instagram.com/madetouplift/',
  email: EMAIL,
}

function OrderConfirmation({ location }) {
  const classes = useStyles()

  const [campaign, setCampaign] = React.useState(null)
  const [name, setName] = React.useState(null)
  const [email, setEmail] = React.useState(null)

  React.useEffect(() => {
    const { confirmation } = queryString.parse(location.search)

    if (confirmation) {
      Cart.completePurchase(confirmation).then(data => {
        setCampaign(data.campaign)
        setName(data.name)
        setEmail(data.email)
      })
    }
  }, [location.search])

  function content() {
    return (
      <Grid container className={classes.container}>
        {/** SHARE BANNER */}
        <Grid className={classes.banner}>
          <Card className={classes.bannerCard}>
            <Grid className={classes.bannerText}>
              <Typography className={classes.bannerHeader}>
                Want an extra sticker for free?
              </Typography>
              <Typography className={classes.bannerSubtext}>
                Share this campaign with your friends and we'll automatically
                include one! 😊
              </Typography>
            </Grid>
            <Grid>
              <SocialLinks
                oneColor="black"
                className={classes.social_links}
                links={links}
              />
            </Grid>
          </Card>
        </Grid>
        {/** MAIN CONTENT */}
        <Grid className={classes.main}>
          {/** ORDER COMPLETED CARD */}
          <Grid>
            <img
              src="https://i.imgur.com/rCjlOWw.png"
              className={classes.orderCompletedCard}
              alt="pink background decoration"
            />
          </Grid>
          {/** INFO: ORDER # AND FAQs */}
          <Grid className={classes.text}>
            <Typography className={classes.infoHeader}>
              <CustomIcon
                oneColor="green"
                className={classes.icon}
                icon={new IconWrapper('check_circle')}
              />
              Order Success!
            </Typography>
            {/* <Typography className={classes.infoSubtext}>
              Your Order No: 999999
            </Typography> */}
            <Typography className={classes.infoQuestion}>
              When will my order ship?
            </Typography>
            <Typography className={classes.infoAnswer}>
              You can expect your oder to ship within 7 days of the completion
              of the campaign.
            </Typography>
            <Typography className={classes.infoQuestion}>
              How long will it take for my order to get to me?
            </Typography>
            <Typography className={classes.infoAnswer}>
              Please allow up to 2 weeks for shipping.
            </Typography>
            <Typography className={classes.infoQuestion}>
              What to expect for sticker orders?
            </Typography>
            <Typography className={classes.infoAnswer}>
              Sticker orders do not come with tracking they are sent as letter
              mail with a stamp.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Page title="Order Confirmation | Made to Uplift" content={content()} />
  )
}

export default withSnackbar(OrderConfirmation)
